import { compare } from '@/services/utils'

export default {
  model: {
    prop: 'selectedItems',
    event: 'update'
  },

  props: {
    title: {
      required: true,
      type: String
    },

    options: {
      required: true,
      type: Array
    },

    selectedItems: {
      required: true,
      type: Array
    },
  },

  data() {
    return {
      show: false,
      search: '',
      internalSelectedItems: this.getOrderedItems(_.cloneDeep(this.selectedItems)),
      internalOptions: _.cloneDeep(this.options)
    }
  },

  methods: {
    /**
     * This is done this way (without using shortcut v-model) because
     * we're using Filters <--> FilterSelector <--> BaseSelectList
     * communication.
     *
     * When user clears all filters, "Filters" component sends empty
     * arrays, thus, selectedItems property should be updated.
     *
     * However, the property should also be updated when BaseSelecList
     * component has changed v-model value. At the end it turns out
     * to be infinite updating loop.
     *
     * @param items
     */
    onListUpdate(items) {
      this.internalSelectedItems = this.getOrderedItems(_.cloneDeep(items))
      this.$emit('update', this.internalSelectedItems)
    },

    clear() {
      this.internalSelectedItems = []
      this.$emit('update', [])
    },

    placeSelectedItemsOnTop() {
      let unselectedOptions = this.internalOptions.filter(option => {
        return !this.internalSelectedItems.some(item => item.ml_id === option.ml_id)
      })

      this.internalOptions = [
        ...this.internalSelectedItems,
        ...this.getOrderedItems(unselectedOptions)
      ]
    },

    getOrderedItems(items) {
      return items.sort((a, b) => {
        return compare(a, b, 'title')
      })
    }
  },

  watch: {
    selectedItems: function(items) {
      this.internalSelectedItems = this.getOrderedItems(_.cloneDeep(items))
    },

    show(isFilterListShown) {
      if (!isFilterListShown) {
        return
      }

      this.placeSelectedItemsOnTop()
    }
  }
}