<template>
  <div class="clothing-filters">
    <div class="open-filters-button">
      <div class="indicator"
           v-if="numberOfActiveFilters > 0">
        {{ numberOfActiveFilters }}
      </div>
      <base-button-icon :title="$t('studio.catalogue.filtersTooltip')"
                        icon="filters"
                        @click="show = true"
                        v-tippy>
      </base-button-icon>
    </div>

    <portal to="clothingFiltersPanel" v-if="show">
      <transition enter-active-class="animated slideInRight"
                  leave-active-class="animated slideOutRight">
        <div class="clothing-filter-panel">
          <div class="header">
            <a href="#" class="close"
               @click.prevent="hideFilterPanel">
              <base-icon icon="tick"></base-icon>
              <div class="close-text">
                {{ $t('studio.catalogue.filters.close') }}
                <span class="goods-amount">
                ({{ (goodsAmount || 0) | goodsNumber }} {{ $t('studio.catalogue.filters.goodsAmount') }})
              </span>
              </div>
            </a>
            <div class="reset">
              <a href="#" @click.prevent="resetFilters">
                {{ $t('studio.catalogue.filters.reset') }}
              </a>
            </div>
          </div>

          <filter-selector :title="$t('studio.catalogue.filters.store')"
                           :options="availablePartners"
                           :selected-items="internalFilters.partners"
                           @update="updateFilters('partners', $event)">
          </filter-selector>

          <portal-target name="filterPanelPortal"></portal-target>
        </div>
      </transition>
    </portal>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import FilterSelector from './FilterSelector/FilterSelector.vue'
import bus from '@/bus'

const defaultFilters = {
  partners: []
}

export default {
  model: {
    prop: 'filters',
    event: 'update'
  },

  props: {
    filters: {
      required: true,
      type: Object
    }
  },

  components: {
    FilterSelector,
    VuePerfectScrollbar
  },

  data() {
    return {
      show: false,
      internalFilters: [],

      filtersDisplayed: {
        partners: false
      },

      goodsAmount: null,
    }
  },

  created() {
    bus.$on('goodsAmountUpdated', this.setGoodsAmount)
  },

  methods: {
    showFilter(name) {
      this.hideFilters()
      this.filtersDisplayed[name] = true
    },

    hideFilters() {
      Object.keys(this.filtersDisplayed).forEach((key, index) => {
        this.filtersDisplayed[key] = false
      })
    },

    updateFilters(type, value) {
      this.internalFilters[type] = _.cloneDeep(value)
      this.$emit('update', _.cloneDeep(this.internalFilters))
    },

    resetFilters() {
      this.internalFilters = defaultFilters
      this.$emit('update', _.cloneDeep(this.internalFilters))
    },

    hideFilterPanel() {
      this.show = false
    },

    setGoodsAmount(amount) {
      this.goodsAmount = amount
    }
  },

  computed: {
    numberOfActiveFilters() {
      const arrayFilters = [
        'partners'
      ]

      let number = 0

      arrayFilters.forEach(filter => {
        if (this.filters[filter] && this.filters[filter].length > 0) {
          number++
        }
      })

      return number
    },

    ...mapGetters('handbooks', {
      availablePartners: 'getAvailablePartners',
    })
  },

  watch: {
    filters: {
      handler(value) {
        this.internalFilters = _.cloneDeep(value)
      },
      immediate: true, // This is important
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.clothing-filters {
  .open-filters-button {
    position: relative;

    .indicator {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      z-index: map-get($zIndexes, icon-button-indicator);
      right: 0;
      top: 0;

      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #F75B5B;

      font-size: 9px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
    }
  }
}

.clothing-filter-panel {
  position: fixed;
  top: $header-height;
  right: 0;
  z-index: map-get($zIndexes, filter-panel);
  height: 100%;
  width: 500px;
  padding: 20px 0 20px 20px;

  background: #FEFEFE;
  box-shadow: -2px 0 4px 0 rgba(234, 234, 234, 0.50);

  overflow: hidden;

  & > .header {
    display: flex;
    justify-content: space-between;

    margin: 0 20px 20px 0;

    .close {
      display: flex;

      .close-text {
        display: flex;
        align-items: center;

        .goods-amount {
          margin-left: 3px;
          color: #B7B7B7;
          font-size: 12px;
        }
      }

      svg {
        margin-right: 10px;
      }
    }

    .reset {
      text-transform: uppercase;
    }

    a {
      font-size: 16px;
    }
  }

  .filter {
    position: absolute;
    z-index: map-get($zIndexes, filter);
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    background-color: #fff;
  }
}
</style>
