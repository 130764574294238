<template>
  <base-select-tag v-model="selectedGenderOption"
                   :options="genderOptions"></base-select-tag>
</template>

<script>
  export default {
    model: {
      prop: 'gender',
      event: 'update'
    },

    props: {
      gender: {
        required: true,
        type: String
      }
    },

    data() {
      return {
        genderOptions: [
          {
            label: this.$t('shared.genderSelect.female'),
            value: 'female'
          },
          {
            label: this.$t('shared.genderSelect.male'),
            value: 'male'
          }
        ]
      }
    },

    computed: {
      selectedGenderOption: {
        get: function() {
          let genderOption = this.genderOptions.find(gender => gender.value === this.gender)

          return _.cloneDeep(genderOption)
        },

        set: function(genderOption) {
          this.$emit('update', genderOption.value)
        }
      }
    }
  }
</script>