<template>
  <div class="clothing-item-card" :class="classes">
    <item-preview :clothingItem="clothingItem"
                  :buttons="['like', 'suitable']"
                  :size="size"
                  @select="$emit('select')"
                  @studioButtonClick="$emit('studioButtonClick')"
                  @onSuitableClick="openWearModal" />
    <item-description :clothingItem="clothingItem" />
  </div>
</template>

<script>
import ItemDescription from './ClothingItemCardDescription.vue'
import ItemPreview from './ClothingItemCardPreview.vue'

export default {
  props: {
    clothingItem: {
      required: true,
      type: Object
    },

    size: {
      required: false,
      default: 'medium'
    },

    withLink: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  components: {
    ItemPreview,
    ItemDescription
  },

  methods: {
    openWearModal () {
      this.$modal.show('wearGeneration', {
        clothingItemId: this.clothingItem.id
      })
    },

    onPreviewClick() {
      this.$emit('select')
    },

    onStudioButtonClick() {
      this.$emit('studioButtonClick')
    }
  },

  computed: {
    classes() {
      return [
        { 'small': this.size === 'small' },
        { 'medium': this.size === 'medium' },
        { 'mobile': this.size === 'mobile' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>


  .clothing-item-card {

    .preview {
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      background-color: #fff;
      box-shadow: 0 6px 10px 0 rgba(240,240,240,0.50);
      border: 1px solid rgba(0,0,0,0);
      border-radius: 6px;

      margin-bottom: 8px;
      padding: 10px;

      cursor: pointer;

      transition: border 0.2s ease-out;

      .button {
        display: none;
        position: absolute;
        width: 40px;
        height: 40px;
        padding: 10px;
      }

      .button-add-to-artboard {
        top: 0;
        left: 0;
      }

      .button-like {
        top: 0;
        right: 0;
      }

      .discount {
        position: absolute;
        left: 4px;
        bottom: 4px;
        padding: 4px 6px;

        background-color: #9f2943;
        border-radius: 6px;

        font-size: 10px;
        font-weight: bold;
        color: #fff;
      }

      &:hover {
        border: 1px solid $primary-color;
      }

      &:hover .button {
        display: block;
      }
    }

    .price {
      font-family: CormorantGaramond-SemiBold, serif;
      font-size: 22px;
      color: #282828;
      letter-spacing: 0;
      line-height: 22px;
      margin-bottom: 4px;

      &.price-old {
        color: #727272;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 16px;
        text-decoration: line-through;
      }

      &.price-reduced {
        color: #9f2943;
      }
    }

    .title {
      font-family: OpenSans-Regular, sans-serif;
      font-size: 14px;
      color: #797979;
      letter-spacing: 0;
      line-height: 23px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.small {
      width: 150px;

      .preview {
        height: 190px;
      }
    }

    &.medium {
      width: 200px;

      .preview {
        height: 240px;
      }
    }

    &.mobile {
      width: 40%;

      .preview {
        height: 200px;
      }
    }
  }
</style>
