<template>
  <div>
    <div class="price price-old" v-if="clothingItem.discount > 0">
      {{ clothingItem.oldPrice | currency }} {{ clothingItem.currencySymbol }}
    </div>
    <div class="price" :class="{ 'price-reduced': clothingItem.discount }">
      {{ clothingItem.local_price | currency }} {{ clothingItem.currencySymbol }}
    </div>
    <div class="title">{{ clothingItem.brand.title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    clothingItem: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
  .price {
    font-family: CormorantGaramond-SemiBold, serif;
    font-size: 22px;
    color: #282828;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 4px;

    &.price-old {
      color: #727272;
      font-size: 16px;
      margin-bottom: 0;
      line-height: 16px;
      text-decoration: line-through;
    }

    &.price-reduced {
      color: #9f2943;
    }
  }

  .title {
    font-family: OpenSans-Regular, sans-serif;
    font-size: 14px;
    color: #797979;
    letter-spacing: 0;
    line-height: 23px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
