import clothingItemTransformer from '@/services/transformers/clothingItemTransformer'

const baseURL = 'clothing_items'

export default {
  /**
   * Get clothing item list.
   *
   * @param params
   * @returns Promise
   */
  get(params = {}) {
    return axios.get(`${baseURL}/`, {
      params,

      transformResponse: (data) => {
        return Object.assign({}, data, { items: data.items.map(clothingItemTransformer) })
      }
    }).then(response => response.data)
  },

  /**
   * Get specific clothing item.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  find(id, params = {}) {
    return axios.get(`${baseURL}/${id}`, {
      params,

      transformResponse: (data) => {
        return Object.assign({}, clothingItemTransformer(data))
      }
    }).then(response => response.data)
  },

  /**
   * Like clothing item.
   *
   * @param id
   * @returns {*|AxiosPromise<T>}
   */
  like(id) {
    return axios.post(`${baseURL}/${id}/likes`)
  },

  /**
   * Remove like.
   *
   * Notice that this is not dislike.
   *
   * @param id
   */
  removeLike(id) {
    return axios.delete(`${baseURL}/${id}/likes`)
  }
}