<template>
  <router-link class="preview"
               :class="classes"
               :to="{ name: 'clothingItem', params: { id: clothingItem.id }}"
               :style="previewStyle">
    <base-icon-like class="button button-like"
                    v-if="showButton('like')"
                    :active="liked"
                    @click.prevent="onLikeButtonClick" />

    <div class="button button-suitable"
         v-if="showButton('suitable')">
      <base-icon-look class="button button-suitable"
                      :active="liked"
                      @click.prevent="$emit('onSuitableClick')" />
    </div>

    <div class="button button-artboard"
         v-if="showButton('artboard')"
         @click.prevent="$emit('studioButtonClick')">
      <base-icon icon="studio"></base-icon>
    </div>
    <div class="discount"
         v-if="clothingItem.discount">
      {{ clothingItem.discount | discount }}
    </div>
  </router-link>
</template>

<script>
  import { authenticated } from '@/services/auth'
  import clothingItemService from '@/services/queries/clothingItemQueries'

  export default {
    props: {
      // ['like', 'suitable', 'artboard']
      buttons: {
        required: false,
        type: Array,
        default: ['like', 'suitable']
      },

      clothingItem: {
        required: true,
        type: Object
      },

      showLikeButton: {
        required: false,
        type: Boolean,
        default: true
      },

      showAddToArtboardButton: {
        required: false,
        type: Boolean,
        default: false
      },

      showWearWithButton: {
        required: false,
        type: Boolean,
        default: false
      },

      size: {
        required: false,
        default: 'medium'
      }
    },

    data() {
      return {
        liked: this.clothingItem.liked
      }
    },

    methods: {
      showButton (button) {
        return this.buttons.includes(button)
      },

      onPreviewClick(event, good) {
        this.$emit('select')
      },

      onLikeButtonClick() {
        if (!authenticated()) {
          this.openAuthModal({ content: 'login' })
          return
        }

        if (!this.liked) {
          clothingItemService.like(this.clothingItem.id)
        } else {
          clothingItemService.removeLike(this.clothingItem.id)
        }

        this.liked = !this.liked
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    },

    computed: {
      previewStyle() {
        return {
          background: `url(${this.clothingItem.mainImage.middle.url}) center center / contain no-repeat`,
          backgroundColor: '#fff',
          backgroundOrigin: 'content-box'
        }
      },

      classes() {
        return [
          { 'small':  this.size === 'small'  },
          { 'medium': this.size === 'medium' },
          { 'mobile': this.size === 'mobile' }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;
    margin-bottom: 20px;

    background-color: #fff;
    box-shadow: 0 6px 10px 0 rgba(240,240,240,0.50);
    border: 1px solid rgba(0,0,0,0);
    border-radius: 6px;

    cursor: pointer;

    transition: border 0.2s ease-out;

    .button {
      display: none;
      position: absolute;
      width: 40px;
      height: 40px;
      padding: 10px;
    }

    .button-artboard {
      top: 0;
      left: 0;
    }

    .button-like {
      top: 0;
      right: 0;
    }

    .button-suitable {
      top: 0;
      left: 0;
    }

    .discount {
      position: absolute;
      left: 4px;
      bottom: 4px;
      padding: 4px 6px;

      background-color: #9f2943;
      border-radius: 6px;

      font-size: 10px;
      font-weight: bold;
      color: #fff;
    }

    &:hover {
      border: 1px solid $primary-color;
    }

    &:hover .button {
      display: block;
    }

    &.small {
      height: 190px;
    }

    &.medium {
      height: 240px;
    }

    &.mobile {
      height: 200px;
    }
  }
</style>
